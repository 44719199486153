<template>
  <div class="col-lg-6"
    @mouseover="stopTransition"
    @mouseleave="startTransition">

    <carousel class="story-carousel story-carousel--colors"
      :hideArrows="false"
      hideArrowsOnBound
      ref="carrossel">
      <slide v-for="(item, index) in value.hiperlinks" :key="`slide${item.id_carrousel}-${index}`"
        class="story-carousel__slide">
        <link-card
          :value="item.hiperlink"
          :blank="true"
          :linkClass="linkClass"
        />
      </slide>
      <template v-slot:arrows>
        <div class="navigating-btn float-right mr-3">
          <span :class="['dot', 'm-1', isSelected(index) ? 'selected' : '']"
            v-for="(item, index) in value.hiperlinks"
            :key="`btn${item.id_carrousel}-${index}`"
            @click="goToSlide(index)"></span>
        </div>
      </template>
    </carousel>
    <!-- <button class="btn btn-sm btn-outline-cancel" @click="onShow">Console</button> -->
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-snap'
import 'vue-snap/dist/vue-snap.css'
import LinkCard from './LinkCard.vue'

export default {
  components: {
    Carousel,
    Slide,
    LinkCard
  },
  name: 'CarouselCard',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      transitionTimer: 5000,
      slideTrasition: null,
      linkClass: 'col-12 px-0 h-50'
    }
  },
  mounted () {
    this.startTransition()
  },
  beforeDestroy () {
    this.stopTransition()
  },
  methods: {
    onShow () {
    },
    updateValue (newVal) {
      this.$emit('input', newVal)
    },
    goToSlide (index) {
      this.$refs.carrossel.goToSlide(index)
    },
    startTransition () {
      this.slideTrasition = setInterval(() => {
        this.autoTransition()
      }, this.transitionTimer)
    },
    stopTransition () {
      clearInterval(this.slideTrasition)
    },
    autoTransition () {
      let carousel = this.$refs.carrossel
      let index = carousel.currentPage + 1

      if (carousel.boundRight) {
        index = 0
      }

      this.goToSlide(index)
    },
    isSelected (index) {
      if (!this.$util.isset(this.$refs.carrossel)) {
        if (index === 0) {
          return true
        }

        return false
      }

      return this.$refs.carrossel.currentPage === index
    }
  },
  computed: {
    target () {
      if (this.blank) {
        return '_blank'
      }

      return '_self'
    },
    descricao () {
      return this.value.descricao || null
    },
    tags () {
      return this.value.tags || null
    },
    destaque () {
      return this.value.destaque || null
    },
    title () {
      return this.value.titulo || null
    },
    imageUrl () {
      return this.value.image_url || null
    },
    url () {
      return this.value.url || null
    }
  }
}
</script>

<style scoped>
.navigating-btn {
  position:relative;
  margin-top: -7%;
  margin-bottom: 4%;
}
.dot {
  height: 10px;
  width: 10px;
  border-color: #bbb;
  border-style: solid;
  border-width: thin;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}
.selected {
  background-color: #bbb;
}
</style>
