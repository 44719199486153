import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const DashboardService = {

  /** Dashboard */
  getDasboardLinks () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/dashboard`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /** End Dashboard */

  /** Dashboard Admin */
  /**
   * http://localhost/api/v3/dashboard/{id}
   *
   * @param id
   * @returns {Promise<any>}
   */
  getCarousel (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/carrossel/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  toggleCarouselActivation (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v3/carrossel/${id}/toggle/activation`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('Falha ao tentar alterar o registro'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  deleteCarousel (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/carrossel/${id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('Falha ao tentar excluir o registro'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /** Dashboard Admin */

  /** Hiperlinks Admin */
  getHiperlinks (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/dashboard/hiperlinks?${queryString}`, {})
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            reject(err)
            BaseService.checkSesssionExpired(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
  /** End Hiperlinks Admin */
}

export default DashboardService
