<template>
  <a :class="linkClass" :href="url" :target="target" >
    <div :class="['action-card', cardClass]" :style="{ 'background-image': 'url(' + imageUrl + ')' }">
      <div class="action-content">
        <div v-if="destaque" class="action-destaque">
          {{ destaque }}
        </div>

        <div v-if="tags" class="action-tags" v-bind:style="{'bottom': (descricao) ? '80px' : '60px'}">
          {{ tags }}
        </div>

        <div class="action-title" v-bind:style="{'bottom': (descricao) ? '40px' : '20px'}">
          {{ title }}
        </div>

        <div v-if="descricao" class="action-descricao">
          {{ descricao }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  name: 'LinkCard',
  data () {
    return {}
  },
  methods: {
    updateValue (newVal) {
      this.$emit('input', newVal)
    }
  },
  computed: {
    target () {
      if (this.blank) {
        return '_blank'
      }

      return '_self'
    },
    descricao () {
      return this.value.descricao || null
    },
    tags () {
      return this.value.tags || null
    },
    destaque () {
      return this.value.destaque || null
    },
    title () {
      return this.value.titulo || null
    },
    imageUrl () {
      return this.value.image_url || null
    },
    url () {
      return this.value.url || null
    }
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    blank: {
      type: Boolean,
      required: false,
      default: false
    },
    linkClass: {
      type: String,
      required: false,
      default: 'col-lg-4 col-md-6 m-0 p-0 p-3'
    },
    cardClass: {
      type: String,
      required: false,
      default: 'action-card-md'
    }
  }
}
</script>

<style scoped>
.action-card, .action-card * {
  font-size: 12px;
  color: #FAFAFA;
}
.action-card {
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}
.action-card-md {
  height: 270px;
}
.action-card-sm {
  height: 180px;
}
.action-card .action-blur {
  height: 100%;
  width: 100%;
  background: var(--fade, linear-gradient(360deg, rgba(0, 0, 0, 0.60) 15.63%, rgba(0, 0, 0, 0.00) 44.79%));
}
.action-content {
  transition: all 1s ease;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background: var(--fade, linear-gradient(360deg, rgba(0, 0, 0, 0.60) 15.63%, rgba(0, 0, 0, 0.00) 44.79%));
}
.action-content:hover {
  background: var(--fade, linear-gradient(360deg, rgba(0, 0, 0, 0.30) 0.63%, rgba(0, 0, 0, 0.00) 44.79%));
  background-color: rgba(217, 217, 217, 0.4);
}
.action-title {
  position: absolute;
  bottom: 20px;
  margin-left: 5%;
  text-transform: uppercase;
  font-size: 140%;
}
.action-descricao {
  position: absolute;
  bottom: 20px;
  margin-left: 5%;
  width: 90%;
  font-size: 12px;
  font-family: 'pluto_sansextralight';
  white-space: nowrap; /* Impede a quebra de linha */
  overflow: hidden; /* Oculta o conteúdo que ultrapassa a largura */
  text-overflow: ellipsis; /* Adiciona reticências (...) no final do texto */
}
.action-destaque {
  position: absolute;
  top: 16px;
  font-size: 16px;
  margin-left: 5%;
}
.action-tags {
  position: absolute;
  bottom: 60px;
  margin-left: 5%;
  background-color: #5B8FF9;
  padding: 5px 8px 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  font-size: 80%;
  letter-spacing: -0.154px;
  font-weight: bold;
}
</style>
